.card {
  label {
    font-size: 0.8571em;
  }
}

label {
  font-size: 0.8em;
  position: relative;
  top: 4px;
  color: $gray-600;
  margin-left: 2px;
  // color: rgba(0, 0, 0, 0.6);

  &.text-truncate {
    top: 10px;
  }
}

.form-check {
  label {
    font-size: 1em;
    position: relative;
    top: 0px;
    color: $gray-600;
    margin-left: 0px;
    // color: rgba(0, 0, 0, 0.6);

    &.text-truncate {
      top: 10px;
    }
  }
}

.form-control {
  height: unset;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  line-height: normal;
  font-size: 1rem;

  transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;

  &:focus {
    border: 1px solid #999;
  }
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #e3e3e3;
  color: $gray-600;
}

.form-control,
.form-select {
  padding: 10px;

  &.form-select-sm,
  &.form-control-sm {
    padding: 5px 10px;
  }
}

textarea.form-control {
  line-height: 1.5em;
  height: auto;
}

input,
textarea {
  box-shadow: none !important;
  resize: none;
}
