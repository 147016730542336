@import 'font.scss';
@import 'variables.scss';
@import '~bootstrap/scss/bootstrap';
@import 'typography.scss';
@import 'buttons.scss';
@import 'form.scss';
@import 'card.scss';
@import 'modal.scss';

body {
  overflow-y: scroll;
}

#root {
  display: flow-root;

  main {
    min-height: calc(100vh);
    transition: all 0.4s ease-in-out;

    &.gray {
      background-color: $light;
    }
  }
}

.container {
  &.marginauto {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.jumbotron {
  background-color: $light;
  h2 {
    font-weight: 700;
    margin-bottom: 0px;
  }

  h6 {
    font-weight: 500;
    margin-top: 25px;
    text-transform: initial;
    font-size: 1.1em;
  }
}

.badge {
  font-size: 87%;
}

img {
  max-width: 100%;
  border-radius: 3px;
}

dl {
  margin-bottom: 0.5rem;
}

.toast {
  background-color: $white;
}

/// NAV
.tab-nav {
  &.nav {
    display: flex;
    justify-content: center;
    margin: 20px 1px;
    flex-wrap: nowrap;

    .nav-item {
      border-radius: 3px;
      padding: 0;
      padding-bottom: 4px;

      font-weight: 600;
      line-height: 1em;
      font-size: 1.1em;

      .nav-link {
        text-transform: initial;
        color: #444;
        border-radius: 6px;
        margin-right: 10px;
        padding-top: 9px;
        padding-bottom: 9px;

        transition: 0.1s;

        &.active {
          background-color: $primary;
          color: $white;
        }

        &:hover {
          background-color: darken($primary, 15%);
          color: $white;
        }
      }
    }
  }
}

nav.navbar {
  transition: all 0.3s ease-in-out;
  background: #313130;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  min-height: auto;
  margin-bottom: 0;

  .navbar-brand {
    display: inline-block;
    font-size: 1.5em;
    font-weight: 600;
    text-transform: none;
    line-height: 1;

    a {
      color: $white;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
      img {
        vertical-align: middle;
        margin-right: 5px;
        border-radius: 7px;
        height: 25px;
        width: 25px;
      }
    }
  }

  .btn {
    margin: 0;
    padding: 6px 22px;
  }

  .navbar-collapse .nav-item a {
    // padding: 0.5rem 0.7rem;
    // line-height: 1.625rem;
    margin-right: 3px;
    font-size: 0.95em;

    color: white;
    font-weight: 500;
    text-transform: initial;

    &:hover {
      color: #ccc !important;
    }

    &.active,
    &:active,
    &:focus {
      color: white;
    }
  }

  .navbar-toggler {
    width: auto;
    height: auto;
    font-size: 0.8rem;
    color: white;
  }

  .nav-item:not(:last-child) {
    margin-bottom: inherit !important;
  }
  .nav-item {
    margin-top: inherit !important;
  }

  &.navbar-transparent {
    border: 0;
    background-color: transparent;

    .navbar-brand a {
      color: #333 !important;
    }

    .navbar-collapse {
      .nav-item {
        a.nav-link {
          color: #333 !important;

          &:hover {
            color: #555 !important;
          }
        }
      }

      .btn {
        &.btn-primary {
          color: white !important;
        }
      }
    }
  }
}

.info-block {
  background-color: $yellow-100;
  border: 1px solid $yellow-400;
  padding: 9px 12px;
  border-radius: 6px;
  font-size: 0.82em;
  color: $gray-800;

  .info-block-icon {
    color: $blue-400;
    font-size: 20px;
  }
}
