$body-bg: white;
$link-decoration: none;

$font-family-sans-serif: 'Open Sans', sans-serif;

$blue: #3092e3;
$green: #3bbf64;
$cyan: #51bcda;
$yellow: #fbc658;
$red: #eb5552;
$light: #f6f6f6;
