html,
body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  // font-size: 1rem;
  font-size: 0.97rem;
  line-height: normal;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

h4 {
  font-weight: 500;
}

h5 {
  font-weight: 600;
}
h6 {
  text-transform: initial;
}
