main.gray .card {
  background-color: $white;
  box-shadow: 0 2px 6px -4px rgba(0, 0, 0, 0.4);
}

.card {
  position: relative;
  overflow: hidden;
  background-color: #f6f6f6;
  border-radius: 6px;
  box-shadow: 0 3px 4px -4px rgba(0, 0, 0, 0.3);
  border: 0;
  margin-bottom: 20px;
  color: #252422;
  transition: transform 0.3s cubic-bezier(0.34, 2, 0.6, 1), box-shadow 0.2s ease,
    -webkit-transform 0.3s cubic-bezier(0.34, 2, 0.6, 1);

  &.card-user {
    overflow: hidden;
    .card-body {
      min-height: 130px;
      z-index: 1000;
    }
  }

  .card-header {
    padding: 15px 15px 0;
    border: 0;
    background-color: transparent;
  }

  .card-body {
    padding: 15px 15px 10px;
  }
}

.card-main {
  .image {
    height: 130px;

    img {
      border-radius: 6px;
    }
  }

  .card-main-title {
    text-align: center;
    text-transform: none;
    margin-top: -77px;

    a + p.description {
      margin-top: -7px;
    }

    .avatar {
      width: 124px;
      height: 124px;
      border: 2px solid #fff;
      position: relative;
    }

    h4 {
      font-weight: 700;
      margin-top: 10px;
      margin-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .card-body {
    min-height: 240px;
  }

  hr {
    margin: 5px 15px 15px;
  }

  .card-body + .card-footer {
    padding-top: 0;
  }

  .card-footer {
    h5 {
      font-size: 1.25em;
      margin-bottom: 0;
    }
  }

  .button-container {
    margin-bottom: 6px;
    text-align: center;
  }
}

@include media-breakpoint-down(sm) {
  // @media screen and (max-width: $sm) {
  .card {
    box-shadow: none;
    border-radius: 6px;
    border: 1px solid #ddd;
  }
  .card-user .image img {
    border-radius: 6px;
  }
}
