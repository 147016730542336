.btn {
  font-weight: 600;
  text-transform: none;
  line-height: normal;
  margin: 10px 1px;
  padding: 11px 22px;
  font-size: 0.95em;
  outline: none;
  box-shadow: none !important;

  user-select: none;

  &.btn-round {
    border-radius: 100px;
  }

  &.btn-primary,
  &.btn-info,
  &.btn-danger,
  &.btn-success {
    color: $white;
  }

  &.btn-sm {
    font-size: 0.8571em;
    border-radius: 3px;
    padding: 5px 15px;
  }

  &.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }

  &.btn-outline-secondary {
    background: transparent;
    box-shadow: inset 0px 0px 0px 2px #6c757d;
    color: #6c757d;
    opacity: 1;
    filter: alpha(opacity=100);
  }

  &.btn-outline-light {
    background: transparent;
    box-shadow: inset 0px 0px 0px 2px transparent;
    color: #999999;
    opacity: 1;
    filter: alpha(opacity=100);

    &:hover {
      background: transparent !important;
      box-shadow: inset 0px 0px 0px 2px #ccc;
      color: #999999 !important;
    }
  }

  &.btn-link,
  &.btn-outline-light,
  &.btn-outline-secondary,
  &.btn-outline-primary {
    &:focus,
    &:active {
      color: inherit !important;
      background-color: inherit !important;
    }
  }

  &.btn-link {
    background-color: transparent;
    border: none;
    color: #66615b;
    padding: 0.5rem 0.7rem;
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:not(:focus) {
      &:hover {
        color: inherit !important;
        background-color: inherit !important;
      }
    }
  }
}

.btn-group .btn:focus {
  background-color: $primary !important;
  &:hover {
    background-color: darken($primary, 15%) !important;
  }
}
