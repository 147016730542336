// LIGHTBOX
.ReactModal__Overlay {
  z-index: 1040 !important;
  .ril__captionContent {
    width: 100%;
  }
}

// Modal
.modal {
  .modal-dialog {
    max-width: 600px;

    &.modal-sm {
      max-width: 400px;
    }

    .modal-content {
      border-radius: 8px;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);

      .modal-header {
        padding-bottom: 0.5em;
        border: none;

        .modal-title {
          font-size: 1.3rem;
          text-align: left;
          font-weight: 600;
          padding-left: 0;
          margin-top: 0;
          margin-bottom: 0;
          width: 100%;
        }

        .close {
          position: absolute;
          top: 5px;
          right: 12px;
          font-size: 35px;
          padding: 0;
          margin: 0;
          font-weight: 300;
        }
      }

      .modal-footer {
        button {
          margin-top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}
